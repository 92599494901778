import axios from 'axios';
export default class CancelamentoService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    async getEmolumentos(params) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'emolumentos',
                {
                    params: params,
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    async getEmolumentoEstimado(params) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'emolumentos/calculo',
                {
                    params: params,
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    async projetarProxAno(params) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'emolumentos/batch',
                params,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    async putEmolumento(emolumento) {
        try {
            const path = emolumento.id_emolumento
                ? `/${emolumento.id_emolumento}`
                : '';
            const response = await axios.put(
                process.env.VUE_APP_RECUPERI_API + 'emolumentos' + path,

                emolumento,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    async postEmolumento(emolumento) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'emolumentos',

                emolumento,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
}
